import { bindings, hook } from '@vl/redata';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import CFAdvisorProfileFormatter from '@uz/unitz-models/CFAdvisorProfileModel/formatter';
import AdvisorProfileFormatter from '@uz/unitz-models/AdvisorProfileModel/formatter';
import _ from 'lodash';

const bindData = bindings({
  CourseInfomationHeader: {
    rules: [
      [
        'data',
        {
          data: {
            '@item': hook((ctx) => {
              const course = ctx.get('@item');
              const advisor = _.get(course, 'advisor');
              const profile = _.get(course, 'advisor.profile');
              const rating = {
                ratingTxt: CFAdvisorProfileFormatter.reviewScore(ctx)(advisor),
                ratingScore: CFAdvisorProfileFormatter.reviewScore(ctx)(advisor),
                totalScore: CFAdvisorProfileFormatter.reviewScore(ctx)(advisor),
              };

              const result = {
                ...course,
                displayName: CourseFormatter.displayName(ctx)(course),
                avatarUrl: CourseFormatter.avatarUrl(ctx)(course),
                reviewCourseCount: CourseFormatter.totalRate(ctx)(course),
                profile: {
                  displayName: AdvisorProfileFormatter.displayName(ctx)(profile),
                  avatarUrl: AdvisorProfileFormatter.avatarUrl(ctx)(profile),
                },
                rating,
              };
              return result;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
