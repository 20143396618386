import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRoute from '@vl/hooks/useGbRoute';
import { hook, bindings } from '@vl/redata';
import _ from 'lodash';

export default bindings({
  formContainer: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const form = useFormik({
                initialValues: {
                  actionCode: `${ctx.get('routeParams.oobCode')}`,
                  password: '',
                  passwordConfirm: '',
                  email: '',
                },
                onSubmit: async (values, actions) => {
                  try {
                    const auth = ctx.apply('authModel.auth');
                    await auth.confirmPasswordReset(values.actionCode, values.password);
                    actions.setStatus({
                      success: true,
                    });
                    await auth.signInWithEmailAndPassword(values.email, values.password);
                    const redirectUrl = ctx.get('routeParams.continueUrl') || window.location.origin;
                    // const redirectUrl = window.location.origin;
                    window.location.href = redirectUrl;
                  } catch (error) {
                    const code = _.get(error, 'code');
                    if (code) {
                      error.message = ctx.apply('i18n.t', `FirebaseMessage.${code}.message`);
                    }
                    actions.setStatus({
                      error,
                    });
                  }
                },
                validationSchema: Yup.object().shape({
                  actionCode: Yup.string().required(ctx.apply('i18n.t', 'Form.required')),
                  email: Yup.string().required(ctx.apply('i18n.t', 'Form.required')),
                  password: Yup.string().required(ctx.apply('i18n.t', 'Form.required')),
                  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
                }),
              });
              React.useEffect(() => {
                (async () => {
                  try {
                    const auth = ctx.apply('authModel.auth');
                    const email = await auth.verifyPasswordResetCode(form.values.actionCode);
                    form.setFieldValue('email', email);
                  } catch (error) {
                    const code = _.get(error, 'code');
                    if (code) {
                      error.message = ctx.apply('i18n.t', `FirebaseMessage.${code}.message`);
                    }
                    form.setStatus({
                      error,
                    });
                  }
                })();
              }, []);

              return form;
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting');
            }),
          },
        },
      ],
    ],
  },
});
