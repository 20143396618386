import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import Url from 'url-parse';
import qs from 'query-string';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),
            item: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              return routeParams;
            }),
            routeParams: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              return routeParams;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
});

export default bindData;
