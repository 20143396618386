import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import FormVerifyEmail from '@uz/unitz-components-web/FormVerifyEmail';
import FormResetPassword from '@uz/unitz-components-web/FormResetPassword';
import ToolBrandAuth from '@uz/unitz-tool-components/ToolBrandAuth';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import FormSignInLink from '@uz/unitz-components-web/FormSignInLink';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <DIV className="isLoading">
        <div className="fixed z-10 w-full h-screen bg-white select-none top-20">
          <LoadingScreen />
        </div>
      </DIV>
      <div className="grid grid-cols-1 gap-5 my-0 lg:grid-cols-2 sm:my-11 md:mx-28 lg:mx-4 sm:wrapper app-row xl:mx-auto">
        <ToolBrandAuth />
        {ctx.debug(() => {
          ctx.set('@item', ctx.get('item'));
        })}
        {(ctx.get('routeParams.mode') === 'verifyEmail') && <FormVerifyEmail />}
        {(ctx.get('routeParams.mode') === 'resetPassword') && <FormResetPassword />}
        {(ctx.get('routeParams.mode') === 'signIn') && <FormSignInLink />}
        {/* <FormLogin /> */}
      </div>
    </DIV>
  );
};

export default displayName(Index);
