import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRoute from '@vl/hooks/useGbRoute';
import { hook, bindings } from '@vl/redata';
import _ from 'lodash';

export default bindings({
  formContainer: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const form = useFormik({
                initialValues: {
                  actionCode: ctx.get('routeParams.oobCode'),
                  email: '',
                },
                onSubmit: async (values, actions) => {
                  const userId = ctx.apply('authModel.getUserId');
                  try {
                    const auth = ctx.apply('authModel.auth');
                    let email = window.localStorage.getItem('emailForSignIn');
                    email = values.email || atob(decodeURIComponent(ctx.get('routeParams.verify_email')));
                    if (!email) {
                      email = window.prompt('Please provide your email for confirmation');
                    }
                    const href = _.replace(window.location.href, `&verify_email=${email}`, '');
                    if (email && !userId) {
                      const userData = await auth.signInWithEmailLink(email, href);
                      await new Promise((res) => setTimeout(res, 1000 * 2));
                      actions.setStatus({
                        success: true,
                      });
                    }
                  } catch (error) {
                    const code = _.get(error, 'code');
                    if (code) {
                      error.message = ctx.apply('i18n.t', `FirebaseMessage.${code}.message`);
                    }
                    actions.setStatus({
                      error,
                    });
                  }
                  const redirectUrl = ctx.get('routeParams.continueUrl') || window.location.origin;
                  window.location.href = redirectUrl;
                },
                validationSchema: Yup.object().shape({
                  actionCode: Yup.string().required(ctx.apply('i18n.t', 'Form.required')),
                }),
              });
              React.useEffect(() => {
                form.handleSubmit();
              }, []);

              return form;
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting');
            }),
          },
        },
      ],
    ],
  },
});
