import { bindings, hook } from '@vl/redata';

const bindData = bindings({
  brandAuth: {
    rules: [
      [
        'data',
        {
          data: {
            sectionData: hook((ctx) => {
              const section = ctx.apply('ctf.findSection', { name: 'AuthBrand' });
              return {
                section,
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
