import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRoute from '@vl/hooks/useGbRoute';
import { hook, bindings } from '@vl/redata';
import _ from 'lodash';

export default bindings({
  formContainer: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const form = useFormik({
                initialValues: {
                  actionCode: ctx.get('routeParams.oobCode'),
                },
                onSubmit: async (values, actions) => {
                  try {
                    const auth = ctx.apply('authModel.auth');
                    await auth.applyActionCode(values.actionCode);
                    actions.setStatus({
                      success: true,
                    });
                  } catch (error) {
                    const code = _.get(error, 'code');
                    if (code) {
                      error.message = ctx.apply('i18n.t', `FirebaseMessage.${code}.message`);
                    }
                    actions.setStatus({
                      error,
                    });
                  }
                },
                validationSchema: Yup.object().shape({
                  actionCode: Yup.string().required(ctx.apply('i18n.t', 'Form.required')),
                }),
              });
              React.useEffect(() => {
                form.handleSubmit();
              }, []);

              return form;
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting');
            }),
          },
        },
      ],
    ],
  },
});
