import React from 'react';
import { Button, Row, Col, Alert, Divider, Space, Checkbox } from 'antd';
import { Link } from '@uz/unitz-components-web/Link';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Input, Form, FormItem } from 'formik-antd';
import styled from 'styled-components';
import CourseInfomationHeader from '@uz/unitz-components-web/CourseInfomationHeader';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRoute';
import { useLocalStorage } from '@vl/hooks/useLocalStorageWeb';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

const CustomInput = styled(FormItem)`
  .ant-input-affix-wrapper,
  .ant-input {
    border-radius: 8px !important;
    border-color: ${gstyles.colors.border} !important;
    &::-webkit-input-placeholder {
      color: ${gstyles.colors.sub};
    }
    &::-ms-input-placeholder {
      color: ${gstyles.colors.sub};
    }
    &::placeholder {
      color: ${gstyles.colors.sub};
    }
  }
`;

const CustomButton = styled.div`
  .ant-btn {
    border: 1px solid ${gstyles.colors.border};
  }
`;

export default () => {
  const route = useRoute();
  const [currentUser, $currentUser] = useLocalStorage('@NA::currentUser', null);
  return (
    <DIV className="formContainer">
      <div
        className="bg-white500 rounded-lg pt-6"
        style={{
          boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.08)',
        }}
      >
        <h3 className="text-2xl font-semibold text-center text-brandb500">{ctx.apply('i18n.t', 'Signup.verifyEmailTitle')}</h3>

        <div className="p-4 lg:p-6">
          <FormProvider form={ctx.get('form')}>
            <Form {...layout} className="verify-email-form" id="verify-email-form">
              <Row>
                <Col className="formStatus" span={24}>
                  {!!ctx.get('form.status.error') && (
                    <div className="py-2">
                      <Alert message={ctx.get('form.status.error.message')} type="error" />
                    </div>
                  )}
                  {!!ctx.get('form.status.success') && (
                    <div className="py-2">
                      <Alert message={ctx.apply('i18n.t', 'Signup.verifyEmailSuccessStatus')} type="success" />
                    </div>
                  )}
                </Col>
              </Row>
              {!!ctx.get('form.status.success') && (
                <Row className="mt-6 mb-8">
                  <Col span={24}>
                    <Button
                      className="shadow-sm"
                      size="large"
                      type="primary"
                      htmlType="button"
                      id="submit"
                      disabled={!ctx.get('canSubmit')}
                      loading={ctx.get('form.isSubmitting')}
                      block
                      onClick={async () => {
                        // const redirectUrl = ctx.get('routeParams.continueUrl') || window.location.origin;
                        const redirectUrl = `${window.location.origin}?redirect=true`;
                        // await new Promise((res) => setTimeout(res, 1000 * 2));
                        // window.location.href = redirectUrl;
                        // route.navigateExternal(redirectUrl);
                        $currentUser(null);
                        window.location.replace(redirectUrl);
                      }}
                    >
                      <span id="button-text">{ctx.apply('i18n.t', 'Signup.verifyEmailSuccessContinue')}</span>
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </FormProvider>
        </div>
      </div>
    </DIV>
  );
};
