import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import gstyles from '@vl/gstyles';
import { Image } from '@uz/unitz-components-web/Image';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import LinkPlayer from '@uz/unitz-components-web/LinkPlayer';
import cx from 'classnames';

export const View8 = ({ fullLayout }) => {
  return (
    <DIV className="CourseInfomationHeader">
      <div
        style={{ backdropFilter: 'blur(50px)' }}
        className={cx('animate-opacity bg-black400', {
          ' py-3': !fullLayout,
        })}
      >
        <div
          className={cx('py-0', {
            'px-4 lg:px-6': fullLayout,
            'app-row wrapper': !fullLayout,
          })}
        >
          <div className="flex items-center space-x-4">
            <div>
              {ctx.get('@item.video_url') ? (
                <LinkPlayer to={ctx.get('@item.video_url')}>
                  <div className="relative">
                    {!!ctx.get('@item.photo_url') ? (
                      <ResponsiveProps
                        xs={{ style: { maxHeight: '60px', maxWidth: '100px', objectFit: 'cover' }, height: 60 }}
                        md={{ style: { maxHeight: '120px', maxWidth: '156px', objectFit: 'cover' }, height: 120 }}
                      >
                        {(resProps) => <Image src={ctx.get('@item.avatarUrl')} {...resProps} />}
                      </ResponsiveProps>
                    ) : (
                      <ResponsiveProps
                        xs={{ style: { maxHeight: '60px', maxWidth: '100px', objectFit: 'cover' }, height: 60 }}
                        md={{ style: { maxHeight: '120px', maxWidth: '156px', objectFit: 'cover' }, height: 120 }}
                      >
                        {(resProps) => <Image alt="avt" src={ctx.get('@item.avatarUrl')} {...resProps} />}
                      </ResponsiveProps>
                    )}
                    <div className="absolute flex justify-center items-center border-white400 bg-black200 border-4 opacity-90 hover:opacity-80 duration-400 h-12 w-12 rounded-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      {gstyles.icons({ name: 'play', size: 24, fill: gstyles.colors.white500 })}
                    </div>
                  </div>
                </LinkPlayer>
              ) : (
                <ResponsiveProps
                  xs={{ style: { maxHeight: '60px', maxWidth: '100px', objectFit: 'cover' }, height: 60 }}
                  md={{ style: { maxHeight: '120px', maxWidth: '156px', objectFit: 'cover' }, height: 120 }}
                >
                  {(resProps) => <Image alt="avt" src={ctx.get('@item.avatarUrl')} {...resProps} />}
                </ResponsiveProps>
              )}
            </div>

            <div>
              <div className="flex justify-between flex-col min-md:flex-row">
                {!!fullLayout ? (
                  <h1 className="text-base md:text-xl font-semibold text-white500">{ctx.get('@item.name')}</h1>
                ) : (
                  <h1 className="text-base md:text-2xl w-full lg:w-1/2 xl:w-2/3 text-white500 font-semibold min-lg:pr-12">
                    {ctx.get('@item.name')}
                  </h1>
                )}

                {/* <div className="static max-md:flex justify-end min-md:justify-start items-end min-md:items-start">
                    <CourseStatusIndicator className="w-auto mx-2" type="label" />
                  </div> */}
              </div>
              <div className="flex items-start justify-between min-md:justify-start flex-row min-md:items-center">
                <div className="flex items-center">
                  <Avatar
                    alt={ctx.get('@item.profile.displayName')}
                    size={{ xs: 24, md: 30 }}
                    style={{ objectFit: 'cover' }}
                    src={ctx.get('@item.profile.avatarUrl')}
                  />
                  <div className="ml-2 -mb-1 text-xs md:text-sm text-white500 font-normal">
                    {ctx.get('@item.profile.displayName')}
                  </div>
                </div>

                <div className="flex items-center min-md:ml-8">
                  <div>
                    {gstyles.icons({ name: 'star', size: 20, fill: gstyles.colors.yellow500, className: 'mt-1' })}
                  </div>
                  <div className="flex items-center ml-2 -mb-1">
                    <div className="text-xs md:text-base text-white500">{ctx.get('@item.rating.ratingScore')}</div>
                    {!!ctx.get('@item.reviewCourseCount') && (
                      <div className="ml-1 text-xs md:text-base text-white500">({ctx.get('@item.reviewCourseCount')})</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
